import React from "react";

function Deleo(){
    return(
        <div>
            <h1>Hello Worldix</h1>
        </div>
    )
}

export default Deleo;