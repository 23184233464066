import React from "react";

function Alex(){
    return(
        <div>
            <h1>Hello Worldi</h1>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <p id="perrito">PERRITO CHICHON</p>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
            <h2>Hello Worldi</h2>
        </div>
    )
}

export default Alex;